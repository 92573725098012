<template>
  <div>
    <c-tab
      ref="ctomissTab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="popupParam"
          :hazard.sync="hazard"
          :attachInfo.sync="attachInfo"
          :attachResultInfo.sync="attachResultInfo"
          :contentHeight="contentHeight"
          :disabled.sync="disabled"
          @getDetail="getDetail"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazardInvestigationDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaMuscleSopId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: 5,
      tab: 'work',
      hazard: {
        heaMuscleSopId: '',  // 근골격계 단위작업 일련번호
        heaInvestigationPlanId: '',  // 근골격계 조사계획 일련번호
        heaMuscleSopStepCd: '',  // 진행상태
        processCd: '',  // 공정코드
        processName: '',  // 공정명
        mdmSopId: '',  // 안전작업 표준 일련번호
        sopName: '',  // 세부공정(작업) 명
        heaInvestigationTypeCd: '',  // 조사구분
        investigationDate: '',  // 조사일시
        deptCd: '',  // 조사자 부서
        userId: '',  // 조사자 id
        remark: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        heavyWorks: [], // 부담작업
        heavyWorkIncludes: [], // 부담작업 결과 O
        heavyWorkHazards: [], // 유해요인
        workers: [], // 작업자
        situations: [], // 상황조사
        results: [], // 결과표
        imprs: [], // 개선
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'HEA_MUSCLE_HAZARD',
        taskKey: '',
      },
      attachResultInfo: {
        isSubmit: '',
        taskClassCd: 'HEA_MUSCLE_HAZARD_RESULT',
        taskKey: '',
      },
      editable: true,
      detailUrl: '',
    };
  },
  computed: {
    disabled() {
      return this.hazard.heaMuscleSopStepCd === 'HMS000010';
    },
    tabItems() {
      let _items = [
        { name: 'work', icon: 'construction', label: '작업조건 평가', component: () => import(`${'./investiWork.vue'}`), },
        { name: 'hazard', icon: 'engineering', label: '유해요인 조사', component: () => import(`${'./muscleHazardCheck.vue'}`), },
        { name: 'result', icon: 'task', label: '작업분석·평가 결과표', component: () => import(`${'./muscleJobResult.vue'}`), },
        { name: 'impr', icon: 'auto_fix_high', label: '개선현황', component: () => import(`${'./muscleImprStatus.vue'}`), },
      ]
      return _items;
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.muscleSystem.muscleSop.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.heaMuscleSopId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.hazard, _result.data)
        this.$set(this.attachInfo, 'taskKey', this.popupParam.heaMuscleSopId)
        this.$set(this.attachResultInfo, 'taskKey', this.popupParam.heaMuscleSopId)

        /**
         * 처음으로 유해요인 조사하러 온 케이스 체크
         */
        if (!this.hazard.situations || this.hazard.situations.length === 0) {
          this.$comm.getComboItems('HEA_MUSCLE_SOP_SITUATION_GUBUN_CD').then(_result => {
            this.hazard.situations = [];
            this.$_.forEach(_result, item => {
              this.hazard.situations.push({
                heaMuscleSopSituationId: '',  // 근골격계 단위작업 상황조사 일련번호
                heaMuscleSopId: this.popupParam.heaMuscleSopId,  // 근골격계 단위작업 일련번호
                heaInvestigationPlanId: this.hazard.heaInvestigationPlanId,  // 근골격계 조사계획 일련번호
                heaMuscleSopSituationGubunCd: item.code,  // 작업장 상황 조사 구분 코드
                heaMuscleSopSituationGubunName: item.codeName,  // 작업장 상황 조사 구분 코드
                changeFlag: 'N',  // 변화유무
                remark: '',  // 내용요약
                regUserId: this.$store.getters.user.userId,  // 등록자 id
                editFlag: 'C'
              })
            })
          });

          this.$set(this.hazard, 'userId', this.$store.getters.user.userId);
          this.$set(this.hazard, 'deptCd', this.$store.getters.user.deptCd);
        }
      },);
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>
